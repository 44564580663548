<template>
  <div class="e-content">
    <div id="Overview">
      <h1>Overview</h1>
      <b-card title="enterBlock Admin Documentation">
        <b-card-text>enterBlock Admin은 프로젝트 히스토리와 기능의 모듈화 그리고 다양한 컴포넌트를 제공합니다.</b-card-text>
        <b-card-text><span class="font-weight-bold">Created By 🛠:</span> ENTERBLOCK Team
        </b-card-text>
        <b-card-text>엔터블록:
          <b-link
              href="https://enterblock.co/"
              target="_blank"
          >
            https://enterblock.co/
          </b-link>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import {BCard, BCardText, BLink, BImg} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    Prism,
  },
  data() {
    return {
      rightNavMenu: ['Overview'],
      codeNpm:
        `
# For Yarn
yarn

# For npm
npm install
  `,
      codeNpmRun:
          `
# For Yarn
yarn serve

# For npm
npm run serve
  `,
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_RIGHT_NAV_MENU', this.rightNavMenu)
  }
}
</script>
